import React from 'react';
import {
   ChakraProvider, Text,
} from '@chakra-ui/react';
import theme from './theme';
import Layout from './components/layout/layout';
import Panel from './components/layout/panel';
import Chat from './components/chat/chat';
import { Navigate, Route, Routes } from 'react-router-dom';


function App() {

   console.log(theme);

   const styles = {
      hot: { fontWeight: "bold", textTransform: "uppercase" },
      label: { textTransform: "unset", fontStyle: "italic"  },
   };

   return (
      <ChakraProvider theme={theme}>
         <Layout name="layout">
            <Routes>
               <Route path="/" element={ <Navigate to="chats/ww3" replace /> } />
               <Route path="chats" element={<Panel variant="content" name="content" />}>
                  <Route path=":chat" element={<Chat />} />
               </Route>
            </Routes>
            <Panel variant="topics" name="topics">
               <Text {...styles.hot}>War</Text>
               <Text>COVID</Text>
               <Text>Business</Text>
               <Text>Sport</Text>
               <Text>Tech</Text>
               <Text>Entertainment</Text>
            </Panel>
            <Panel variant="sidebar" name="sidebar">
               <Text>Stories</Text>
               <Text><b>People</b></Text>
               <Text>Imagery</Text>
               <Text>My feed</Text>
            </Panel>
         </Layout>
      </ChakraProvider>
   );
}

export default App;
